import { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

// Permissions
import {
    canViewAnyCompany, canViewCompany, canCreateCompany, canUpdateCompany,
    canViewAnyWorkSituation, canViewWorkSituation, canCreateWorkSituation, canUpdateWorkSituation,
    canViewReflectionPhase, canCreateReflectionPhase, canUpdateReflectionPhase,
    canViewSignatureGroup, canCreateSignatureGroup,
    canCreateUser, canCreateRoadmap,
} from 'permissions';
import { RequirePerm } from '../index';

// Views
import ActionWorkSituationFrom from 'views/authenticated/work-situations/action-work-situation-from';
import ActionReflectionPhaseFrom from 'views/authenticated/reflection-phases/action-reflection-phase-from';

/* * *
* Lazy Imports
*/

const Companies = lazy(() => import('../../../views/authenticated/companies'));
const Company = lazy(() => import('../../../views/authenticated/companies/company'));
const CreateCompany = lazy(() => import('../../../views/authenticated/companies/create-company'));
const UpdateCompany = lazy(() => import('../../../views/authenticated/companies/update-company'));
const CreateSubCompany = lazy(() => import('../../../views/authenticated/companies/create-sub-company'));

const CompanyDashboard = lazy(() => import('../../../views/authenticated/companies/company/company-dashboard'));
const CompanyUsers = lazy(() => import('../../../views/authenticated/companies/company/company-users'));
const CompanyRoadmaps = lazy(() => import('../../../views/authenticated/companies/company/company-roadmaps'));
const CompanySignatureGroups = lazy(() => import('../../../views/authenticated/companies/company/company-signature-groups'));
const CompanyWorkSituations = lazy(() => import('../../../views/authenticated/companies/company/company-work-situations'));
const CompanyProofItems = lazy(() => import('../../../views/authenticated/companies/company/company-proof-items'));

const SignatureGroupFromCompany = lazy(() => import('../../../views/authenticated/signature-groups/signature-group-from-company'));

const CreateUserFromCompany = lazy(() => import('../../../views/authenticated/users/create-user-from-company'));
const CreateRoadmapFromCompany = lazy(() => import('../../../views/authenticated/roadmaps/create-roadmap-from-company'));
const CreateWorkSituationFromCompany = lazy(() => import('../../../views/authenticated/work-situations/create-work-situation-from-company'));
const CreateSignatureGroupFromCompany = lazy(() => import('../../../views/authenticated/signature-groups/create-signature-group-from-company'));

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------------- COMPANY ROUTES ------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function CompanyRoutes() {
    return (
        <Routes>
            <Route index element={<RequirePerm perm={canViewAnyCompany()}><Companies /></RequirePerm>} />
            <Route path="create" element={<RequirePerm perm={canCreateCompany()}><CreateCompany /></RequirePerm>} />
            <Route path=":id">
                <Route path="update" element={<RequirePerm perm={canUpdateCompany()}><UpdateCompany /></RequirePerm>} />
                <Route element={<RequirePerm perm={canViewCompany()}><Company /></RequirePerm>}>
                    <Route index element={<CompanyDashboard />} />
                    <Route path="create-sub" element={<RequirePerm perm={canCreateCompany()}><CreateSubCompany /></RequirePerm>} />
                    <Route path="users" element={<CompanyUsers />} />
                    <Route path="create-user" element={<RequirePerm perm={canCreateUser()}><CreateUserFromCompany /></RequirePerm>} />
                    <Route path="create-signature-group" element={<RequirePerm perm={canCreateSignatureGroup()}><CreateSignatureGroupFromCompany /></RequirePerm>} />
                    <Route path="signature-groups" element={<CompanySignatureGroups />} />
                    <Route path="signature-groups/:signatureGroupId" element={<RequirePerm perm={canViewSignatureGroup()}><SignatureGroupFromCompany /></RequirePerm>} />
                    <Route path="create-roadmap" element={<RequirePerm perm={canCreateRoadmap()}><CreateRoadmapFromCompany /></RequirePerm>} />
                    <Route path="roadmaps" element={<CompanyRoadmaps />} />
                    <Route path="proof-items" element={<CompanyProofItems />} />
                    <Route path="create-work-situation" element={<RequirePerm perm={canCreateWorkSituation({})}><CreateWorkSituationFromCompany /></RequirePerm>} />
                    <Route path="work-situations" element={<RequirePerm perm={canViewAnyWorkSituation()}><CompanyWorkSituations /></RequirePerm>} />
                    <Route path="work-situations/:workSituationId">
                        <Route index element={<RequirePerm perm={canViewWorkSituation()}><ActionWorkSituationFrom source="COMPANY" action="VIEW" /></RequirePerm>} />
                        <Route path="update" element={<RequirePerm perm={canUpdateWorkSituation({})}><ActionWorkSituationFrom source="COMPANY" action="UPDATE" /></RequirePerm>} />
                        <Route path="signatures" element={<ActionWorkSituationFrom source="COMPANY" action="SIGN" />} />
                        <Route path="create-reflection-phase/:learnerWorkSituationId" element={<RequirePerm perm={canCreateReflectionPhase({})}><ActionReflectionPhaseFrom source="COMPANY" action="CREATE" /></RequirePerm>} />
                        <Route path="reflection-phase/:reflectionPhaseId">
                            <Route index element={<RequirePerm perm={canViewReflectionPhase()}><ActionReflectionPhaseFrom source="COMPANY" action="VIEW" /></RequirePerm>} />
                            <Route path="update" element={<RequirePerm perm={canUpdateReflectionPhase({})}><ActionReflectionPhaseFrom source="COMPANY" action="UPDATE" /></RequirePerm>} />
                            <Route path="signatures" element={<ActionReflectionPhaseFrom source="COMPANY" action="SIGN" />} />
                        </Route>
                    </Route>
                </Route>
            </Route>
        </Routes>
    );
}
