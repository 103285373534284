import { getPermissions, getStoredUser } from 'services/storage';
import {
    IAtypicalItem, ILearnerWorkSituation, IReflectionPhase, IReflectionPhaseTrainer, 
    ISkillBlock, ITrainerWorkSituation, IUser, IWorkSituation, IRoadmap, IEvaluation, ISupport,
    IOtherTraining, IInternship, IProfessionalPractice, IReflexiveResult,
} from 'services/interfaces';
import { ERepositoryStatus, ERoadmapStatus } from 'services/enums';

const getStorageItems = () => {
    return ({
        storedUser: getStoredUser(),
        permissions: getPermissions() || [],
    });
};

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------------------- USER ----------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const canViewAnyUser = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('users.viewAny');
};

export const canViewUser = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('users.view');
};

export const canCreateUser = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('users.create');
};

export const canUpdateUser = (user: IUser | null = null) => {
    const { permissions } = getStorageItems();

    return (
        permissions.includes('users.update')
        && (
            user == null
            || canTouchUser(user)
        )
    );
};

export const canUploadUserPhoto = (user: IUser | undefined) => {
    const { storedUser } = getStorageItems();

    return !! (
        canUpdateUser()
        && user?.role?.id
        && storedUser?.role?.id
        && (
            Number(storedUser.role.id) === 1
            || Number(storedUser.role.id) < Number(user.role.id)
        )
    );
};

export const canRemoveUserFromCompany = (user: IUser | undefined) => {
    const { permissions } = getStorageItems();

    return !! (
        permissions.includes('users.removeFromCompany')
        && canTouchUser(user)
        && user?.company
    );
};

export const canTouchUser = (user: IUser | undefined) => {
    const { storedUser } = getStorageItems();

    return !! (
        storedUser?.role?.id
        && (
            Number(storedUser.role.id) === 1
            || (user?.role && Number(storedUser.role.id) < Number(user.role.id))
        )
    );
};

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------------- COMPANY ---------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const canViewAnyCompany = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('companies.viewAny');
};

export const canViewCompany = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('companies.view');
};

export const canCreateCompany = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('companies.create');
};

export const canCreateSubCompany = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('companies.createSub');
};

export const canUpdateCompany = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('companies.update');
};

export const canDeleteCompany = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('companies.delete');
};

export const canSetApplicationsForCompany = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('companies.setApplications');
};

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------------- ACTIVITY SECTOR ------------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

export const canViewAnyActivitySector = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('activitySectors.viewAny');
};

export const canViewActivitySector = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('activitySectors.view');
};

export const canCreateActivitySector = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('activitySectors.create');
};

export const canUpdateActivitySector = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('activitySectors.update');
};

export const canDeleteActivitySector = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('activitySectors.delete');
};

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------------- SKILL REPOSITORY ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const canViewAnySkillRepository = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('skillRepositories.viewAny');
};

export const canViewSkillRepository = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('skillRepositories.view');
};

export const canCreateSkillRepository = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('skillRepositories.create');
};

export const canUpdateSkillRepository = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('skillRepositories.update');
};

export const canDeleteSkillRepository = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('skillRepositories.delete');
};

export const canForceDeleteSkillRepository = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('skillRepositories.forceDelete');
};

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------------- SKILL BLOCK ------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

const canUpdateInProgressSkillBlock = (status: ERepositoryStatus | undefined) => {
    const { permissions } = getStorageItems();

    return (
        permissions.includes('skillBlocks.updateInProgress')
        && status === ERepositoryStatus.IN_PROGRESS
    );
};

export const canAddSkillBlock = (status: ERepositoryStatus | undefined) => {
    const { permissions } = getStorageItems();

    return (
        permissions.includes('skillBlocks.update')
        || canUpdateInProgressSkillBlock(status)
    );
};

export const canUpdateSkillBlock = (status: ERepositoryStatus | undefined, skillBlock: ISkillBlock) => {
    const { permissions } = getStorageItems();

    return (
        permissions.includes('skillBlocks.update')
        || (
            canUpdateInProgressSkillBlock(status)
            && (skillBlock?.skillRepositories && skillBlock.skillRepositories.length <= 1)
        )
    );
};

export const canDisconnectSkillBlock = (status: ERepositoryStatus | undefined, skillBlock: ISkillBlock) => {
    const { permissions } = getStorageItems();

    return (
        (skillBlock?.skillRepositories && skillBlock.skillRepositories.length > 1)
        && (
            permissions.includes('skillBlocks.delete')
            || canUpdateInProgressSkillBlock(status)
        )
    );
};

export const canDeleteSkillBlock = (status: ERepositoryStatus | undefined, skillBlock: ISkillBlock) => {
    const { permissions } = getStorageItems();

    return (
        (skillBlock?.skillRepositories && skillBlock.skillRepositories.length <= 1)
        && (
            permissions.includes('skillBlocks.delete')
            || canUpdateInProgressSkillBlock(status)
        )
    );
};

export const canForceDeleteSkillBlock = (status: ERepositoryStatus | undefined) => {
    const { permissions } = getStorageItems();

    return (
        permissions.includes('skillBlocks.forceDelete')
        || canUpdateInProgressSkillBlock(status)
    );
};

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------- ATYPICAL REPOSITORY ---------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const canViewAnyAtypicalRepository = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('atypicalRepositories.viewAny');
};

export const canViewAtypicalRepository = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('atypicalRepositories.view');
};

export const canCreateAtypicalRepository = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('atypicalRepositories.create');
};

export const canUpdateAtypicalRepository = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('atypicalRepositories.update');
};

export const canDeleteAtypicalRepository = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('atypicalRepositories.delete');
};

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ ATYPICAL ITEM ------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const canDeleteAtypicalItem = (atypicalItem: IAtypicalItem) => {
    return (
        canUpdateAtypicalRepository()
        && (atypicalItem?.subItems && atypicalItem.subItems.length === 0)
    );
};

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- CUSTOM REPOSITORY ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const canViewAnyCustomRepository = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('customRepositories.viewAny');
};

export const canViewCustomRepository = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('customRepositories.view');
};

export const canCreateCustomRepository = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('customRepositories.create');
};

export const canUpdateCustomRepository = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('customRepositories.update');
};

export const canDeleteCustomRepository = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('customRepositories.delete');
};

export const canForceDeleteCustomRepository = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('customRepositories.forceDelete');
};

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------- ACTIVITY REPOSITORY ---------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const canViewAnyActivityRepository = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('activityRepositories.viewAny');
};

export const canViewActivityRepository = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('activityRepositories.view');
};

export const canCreateActivityRepository = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('activityRepositories.create');
};

export const canUpdateActivityRepository = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('activityRepositories.update');
};

export const canDeleteActivityRepository = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('activityRepositories.delete');
};

export const canForceDeleteActivityRepository = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('activityRepositories.forceDelete');
};

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------------- ROADMAP ----------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

const canTouchRoadmap = (roadmap: IRoadmap | undefined) => {
    const { storedUser } = getStorageItems();

    return !! (
        storedUser?.company?.id === roadmap?.company?.id
        || storedUser?.companies?.some(c => c.id === roadmap?.company?.id)
        || (storedUser?.role?.id && Number(storedUser.role.id) <= 2)
    );
};

export const canViewAnyRoadmap = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('roadmaps.viewAny');
};

export const canViewRoadmap = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('roadmaps.view');
};

export const canCreateRoadmap = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('roadmaps.create');
};

export const canUpdateRoadmap = (roadmap?: IRoadmap) => {
    const { permissions } = getStorageItems();

    if (roadmap) {
        return (
            permissions.includes('roadmaps.update')
            && canTouchRoadmap(roadmap)
            && roadmap.status !== ERoadmapStatus.ENDED
        );
    }

    return permissions.includes('roadmaps.update');
};

export const canValidateRoadmap = (roadmap: IRoadmap | undefined) => {
    const { permissions } = getStorageItems();

    return (
        permissions.includes('roadmaps.update')
        && canTouchRoadmap(roadmap)
        && roadmap?.status === ERoadmapStatus.DRAFT
    );
};

export const canEndRoadmap = (roadmap: IRoadmap | undefined) => {
    const { permissions } = getStorageItems();

    return (
        permissions.includes('roadmaps.update')
        && canTouchRoadmap(roadmap)
        && roadmap?.status === ERoadmapStatus.VALIDATED
    );
};

export const canDeleteRoadmap = (roadmap: IRoadmap | undefined) => {
    const { permissions } = getStorageItems();

    return (
        permissions.includes('roadmaps.delete')
        && canTouchRoadmap(roadmap)
    );
};

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------------- PLANNED STEP --------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const canViewAnyPlannedStep = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('plannedSteps.viewAny');
};

export const canCreatePlannedStep = (roadmap?: IRoadmap) => {
    const { permissions } = getStorageItems();

    if (roadmap) {
        return (
            permissions.includes('plannedSteps.create')
            && canTouchRoadmap(roadmap)
            && roadmap?.status !== ERoadmapStatus.ENDED
        );
    }

    return permissions.includes('plannedSteps.create');
};

export const canUpdatePlannedStep = (roadmap?: IRoadmap) => {
    const { permissions } = getStorageItems();

    if (roadmap) {
        return (
            permissions.includes('plannedSteps.update')
            && canTouchRoadmap(roadmap)
            && roadmap?.status !== ERoadmapStatus.ENDED
        );
    }

    return permissions.includes('plannedSteps.update');
};

export const canDeletePlannedStep = (roadmap: IRoadmap | undefined) => {
    const { permissions } = getStorageItems();

    return (
        permissions.includes('plannedSteps.delete')
        && canTouchRoadmap(roadmap)
        && roadmap?.status !== ERoadmapStatus.ENDED
    );
};

export const canSchedulePlannedStep = (roadmap: IRoadmap | undefined) => {
    const { permissions } = getStorageItems();

    return (
        permissions.includes('plannedSteps.update')
        && canTouchRoadmap(roadmap)
        && roadmap?.status === ERoadmapStatus.VALIDATED
    );
};

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------------- ROADMAP STEP --------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const canCreateRoadmapStep = (roadmap: IRoadmap | undefined) => {
    const { storedUser, permissions } = getStorageItems();

    return (
        roadmap?.status === ERoadmapStatus.VALIDATED
        && (
            (permissions.includes('roadmaps.update') && canTouchRoadmap(roadmap))
            || roadmap?.hasAccessThroughInternship
            || storedUser?.id === roadmap?.learner?.id
        )
    );
};

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- SELF PLACEMENT -------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const canCreateSelfPlacement = (roadmap: IRoadmap | undefined) => {
    const { storedUser, permissions } = getStorageItems();

    return !! (
        permissions.includes('selfPlacements.create')
        && roadmap?.status === ERoadmapStatus.VALIDATED
        && (
            storedUser?.id === roadmap?.learner?.id
            || (storedUser?.role?.id && Number(storedUser.role.id) === 1)
        )
    );
};

export const canUpdateSelfPlacement = (roadmap: IRoadmap | undefined) => {
    const { storedUser, permissions } = getStorageItems();

    return !! (
        permissions.includes('selfPlacements.update')
        && roadmap?.status !== ERoadmapStatus.DRAFT
        && (
            storedUser?.id === roadmap?.learner?.id
            || (storedUser?.role?.id && Number(storedUser.role.id) === 1)
        )
    );
};

export const canDeleteSelfPlacement = (roadmap: IRoadmap | undefined) => {
    const { storedUser, permissions } = getStorageItems();

    return !! (
        permissions.includes('selfPlacements.delete')
        && (
            storedUser?.id === roadmap?.learner?.id
            || (storedUser?.role?.id && Number(storedUser.role.id) === 1)
        )
    );
};

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------- SELF EVALUATION -------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const canCreateSelfEvaluation = (roadmap: IRoadmap | undefined) => {
    const { storedUser, permissions } = getStorageItems();

    return !! (
        permissions.includes('selfEvaluations.create')
        && roadmap?.status === ERoadmapStatus.VALIDATED
        && (
            storedUser?.id === roadmap?.learner?.id
            || (storedUser?.role?.id && Number(storedUser.role.id) === 1)
        )
    );
};

export const canUpdateSelfEvaluation = (roadmap: IRoadmap | undefined) => {
    const { storedUser, permissions } = getStorageItems();

    return !! (
        permissions.includes('selfEvaluations.update')
        && roadmap?.status !== ERoadmapStatus.DRAFT
        && (
            storedUser?.id === roadmap?.learner?.id
            || (storedUser?.role?.id && Number(storedUser.role.id) === 1)
        )
    );
};

export const canDeleteSelfEvaluation = (roadmap: IRoadmap | undefined) => {
    const { storedUser, permissions } = getStorageItems();

    return !! (
        permissions.includes('selfEvaluations.delete')
        && (
            storedUser?.id === roadmap?.learner?.id
            || (storedUser?.role?.id && Number(storedUser.role.id) === 1)
        )
    );
};

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ EVALUATION ---------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

interface IEvaluationProps {
    evaluation?: IEvaluation;
    roadmap?: IRoadmap;
}

const canTouchEvaluation = (evaluation: IEvaluation | undefined) => {
    const { storedUser } = getStorageItems();

    return !! (
        storedUser?.company?.id === evaluation?.company?.id
        || storedUser?.companies?.some(c => c.id === evaluation?.company?.id)
        || (storedUser?.role?.id && Number(storedUser.role.id) <= 2)
    );
};

export const canCreateEvaluation = (props?: IEvaluationProps) => {
    const { permissions } = getStorageItems();

    if (props?.roadmap) {
        return (
            props.roadmap?.status === ERoadmapStatus.VALIDATED
            && ((
                permissions.includes('evaluations.create')
                && canTouchRoadmap(props.roadmap)
            ) || (
                props.roadmap?.hasAccessThroughInternship
            ))
        );
    }

    return permissions.includes('evaluations.create');
};

export const canUpdateEvaluation = (props?: IEvaluationProps) => {

    const { permissions } = getStorageItems();

    if (props?.roadmap) {
        return (
            canTouchEvaluation(props?.evaluation)
            && props.roadmap?.status !== ERoadmapStatus.DRAFT
            && ((
                permissions.includes('evaluations.update')
                && canTouchRoadmap(props.roadmap)
            ) || (
                props.roadmap?.hasAccessThroughInternship
            ))
        );
    }

    return permissions.includes('evaluations.update');
};

export const canDeleteEvaluation = (props?: IEvaluationProps) => {

    const { permissions } = getStorageItems();

    return !! (
        canTouchEvaluation(props?.evaluation)
        && ((
            permissions.includes('evaluations.delete')
            && canTouchRoadmap(props?.roadmap)
        ) || (
            props?.roadmap?.hasAccessThroughInternship
        ))
    );
};

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- WORK SITUATION -------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

interface IWorkSituationProps {
    workSituation?: IWorkSituation;
    roadmap?: IRoadmap;
    roleId?: string;
}

const canTouchWorkSituation = (workSituation: IWorkSituation | undefined) => {
    const { storedUser } = getStorageItems();

    return !! (
        storedUser?.company?.id === workSituation?.company?.id
        || storedUser?.companies?.some(c => c.id === workSituation?.company?.id)
        || (storedUser?.role?.id && Number(storedUser.role.id) <= 2)
    );
};

export const canViewAnyWorkSituation = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('workSituations.viewAny');
};

export const canViewWorkSituation = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('workSituations.view');
};

export const canCreateWorkSituation = (props?: IWorkSituationProps) => {

    const { permissions, storedUser } = getStorageItems();

    if (props?.roleId) {
        return !! (
            permissions.includes('workSituations.create')
            && storedUser?.role 
            && Number(storedUser.role.id) < Number(props.roleId)
        );
    }

    if (props?.roadmap) {
        return !! (
            props.roadmap?.status === ERoadmapStatus.VALIDATED
            && ((
                permissions.includes('workSituations.create')
                && canTouchRoadmap(props.roadmap)
            ) || (
                props.roadmap?.hasAccessThroughInternship
            ))
        );
    }

    return permissions.includes('workSituations.create');
};

export const canUpdateWorkSituation = (props?: IWorkSituationProps) => {
    const { permissions } = getStorageItems();

    if (props?.roadmap) {
        return (
            canTouchWorkSituation(props?.workSituation)
            && props.roadmap?.status !== ERoadmapStatus.DRAFT
            && ((
                permissions.includes('workSituations.update')
                && canTouchRoadmap(props.roadmap)
            ) || (
                props.roadmap?.hasAccessThroughInternship
            ))
        );
    }

    if (props?.workSituation) {
        return (
            canTouchWorkSituation(props.workSituation)
            && permissions.includes('workSituations.update')
        );
    }

    return permissions.includes('workSituations.update');
};

export const canDeleteWorkSituation = ({ workSituation, roadmap } : { workSituation: IWorkSituation | undefined, roadmap?: IRoadmap }) => {
    const { permissions } = getStorageItems();

    if (roadmap) {
        return (
            canTouchWorkSituation(workSituation)
            && ((
                permissions.includes('workSituations.delete')
                && canTouchRoadmap(roadmap)
            ) || (
                roadmap?.hasAccessThroughInternship
            ))
        );
    }

    return (
        canTouchWorkSituation(workSituation)
        && permissions.includes('workSituations.delete')
    );
};

export const canSignWorkSituation = (workSituation?: IWorkSituation) => {
    const { permissions, storedUser } = getStorageItems();

    return !! (
        permissions.includes('workSituations.update')
        && (
            (storedUser?.role?.id && Number(storedUser.role.id) <= 3)
            || (workSituation?.trainerWorkSituations && workSituation.trainerWorkSituations.some(tws => tws.trainer?.id === storedUser?.id))
        )
    );
};

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------- REFLECTION PHASE ------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

interface IReflectionPhaseProps {
    workSituation?: IWorkSituation;
    roadmap?: IRoadmap;
}

export const canViewReflectionPhase = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('reflectionPhases.view');
};

export const canCreateReflectionPhase = (props?: IReflectionPhaseProps) => {
    const { permissions } = getStorageItems();

    if (props?.roadmap) {
        return (
            props.roadmap?.status === ERoadmapStatus.VALIDATED
            && ((
                permissions.includes('reflectionPhases.create')
                && canTouchRoadmap(props.roadmap)
                && canTouchWorkSituation(props?.workSituation)
            ) || (
                props.roadmap?.hasAccessThroughInternship
            ))
        );
    }

    if (props?.workSituation) {
        return (
            permissions.includes('reflectionPhases.create')
            && canTouchWorkSituation(props.workSituation)
        );
    }

    return permissions.includes('reflectionPhases.create');
};

export const canUpdateReflectionPhase = (props?: IReflectionPhaseProps) => {
    const { permissions } = getStorageItems();

    if (props?.roadmap) {
        return (
            permissions.includes('reflectionPhases.update')
            && canTouchRoadmap(props.roadmap)
            && canTouchWorkSituation(props?.workSituation)
        ) || (
            props.roadmap?.hasAccessThroughInternship
        );
    }

    if (props?.workSituation) {
        return (
            permissions.includes('reflectionPhases.update')
            && canTouchWorkSituation(props.workSituation)
        );
    }

    return permissions.includes('reflectionPhases.update');
};

export const canDeleteReflectionPhase = (props?: IReflectionPhaseProps) => {
    const { permissions } = getStorageItems();

    if (props?.roadmap) {
        return (
            permissions.includes('reflectionPhases.delete')
            && canTouchRoadmap(props.roadmap)
            && canTouchWorkSituation(props?.workSituation)
        ) || (
            props.roadmap?.hasAccessThroughInternship
        );
    }

    if (props?.workSituation) {
        return (
            permissions.includes('reflectionPhases.delete')
            && canTouchWorkSituation(props.workSituation)
        );
    }

    return permissions.includes('reflectionPhases.delete');
};

export const canSignReflectionPhase = (reflectionPhase: IReflectionPhase | undefined) => {
    const { permissions, storedUser } = getStorageItems();

    return !! (
        permissions.includes('reflectionPhases.update')
        && (
            (storedUser?.role?.id && Number(storedUser.role.id) <= 3)
            || (reflectionPhase?.reflectionPhaseTrainers && reflectionPhase.reflectionPhaseTrainers.some(rpt => rpt.trainer?.id === storedUser?.id))
        )
    );
};

export const canUpdateReflectionPhaseLearner = (reflectionPhase?: IReflectionPhase) => {
    const { storedUser } = getStorageItems();

    return !! (
        reflectionPhase?.weather == null
        && (
            reflectionPhase?.learnerWorkSituation?.learner?.id === storedUser?.id
            || (storedUser?.role?.id && Number(storedUser.role.id) === 1)
        )
    );
};

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------------- SUPPORT ----------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

interface ISupportProps {
    support?: ISupport;
    roadmap?: IRoadmap;
}

const canTouchSupport = (support: ISupport | undefined) => {
    const { storedUser } = getStorageItems();

    return !! (
        storedUser?.company?.id === support?.company?.id
        || storedUser?.companies?.some(c => c.id === support?.company?.id)
        || (storedUser?.role?.id && Number(storedUser.role.id) <= 2)
    );
};

export const canTouchOwnSupport = (learnerId: string | undefined) => {
    const { permissions, storedUser } = getStorageItems();

    return (
        permissions.includes('supports.touchOwn')
        && storedUser?.id === learnerId
    );
};

export const canCreateSupport = (roadmap?: IRoadmap) => {
    const { permissions } = getStorageItems();

    if (roadmap) {
        return (
            roadmap?.status === ERoadmapStatus.VALIDATED
            && ((
                permissions.includes('supports.create')
                && canTouchRoadmap(roadmap)
            ) || (
                roadmap?.hasAccessThroughInternship
            ))
        );
    }

    return permissions.includes('supports.create');
};

export const canUpdateSupport = (props?: ISupportProps) => {
    const { permissions } = getStorageItems();

    if (props?.roadmap) {
        return (
            props.roadmap?.status !== ERoadmapStatus.DRAFT
            && ((
                permissions.includes('supports.update')
                && canTouchRoadmap(props.roadmap)
                && canTouchSupport(props?.support)
            ) || (
                props.roadmap?.hasAccessThroughInternship
            ))
        );
    }

    return permissions.includes('supports.update');
};

export const canDeleteSupport = (props?: ISupportProps) => {
    const { permissions } = getStorageItems();

    return !! ((
        permissions.includes('supports.delete')
        && canTouchRoadmap(props?.roadmap)
        && canTouchSupport(props?.support)
    ) || (
        props?.roadmap?.hasAccessThroughInternship
    ));
};

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------- PROFESSIONAL PRACTICE --------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

interface IProfessionalPracticeProps {
    roadmap?: IRoadmap;
    professionalPractice?: IProfessionalPractice;
}

const canTouchProfessionalPractice = (professionalPractice: IProfessionalPractice | undefined) => {
    const { storedUser } = getStorageItems();

    return !! (
        storedUser?.company?.id === professionalPractice?.company?.id
        || storedUser?.companies?.some(c => c.id === professionalPractice?.company?.id)
        || (storedUser?.role?.id && Number(storedUser.role.id) <= 2)
    );
};

export const canTouchOwnProfessionalPractice = (learnerId: string | undefined) => {
    const { permissions, storedUser } = getStorageItems();

    return (
        permissions.includes('professionalPractices.touchOwn')
        && storedUser?.id === learnerId
    );
};

export const canViewAnyProfessionalPractice = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('professionalPractices.viewAny');
};

export const canViewProfessionalPractice = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('professionalPractices.view');
};

export const canCreateProfessionalPractice = (props?: IProfessionalPracticeProps) => {
    const { permissions } = getStorageItems();

    if (props?.roadmap) {
        return (
            props.roadmap?.status === ERoadmapStatus.VALIDATED
            && ((
                permissions.includes('professionalPractices.create')
                && canTouchRoadmap(props.roadmap)
            ) || (
                props.roadmap?.hasAccessThroughInternship
            ))
        );
    }

    return permissions.includes('professionalPractices.create');
};

export const canUpdateProfessionalPractice = (props?: IProfessionalPracticeProps) => {
    const { permissions } = getStorageItems();

    if (props?.roadmap) {
        return (
            props.roadmap?.status !== ERoadmapStatus.DRAFT
            && ((
                permissions.includes('professionalPractices.update')
                && canTouchRoadmap(props.roadmap)
                && canTouchProfessionalPractice(props.professionalPractice)
            ) || (
                props.roadmap?.hasAccessThroughInternship
            ))
        );
    }

    return permissions.includes('professionalPractices.update');
};

export const canDeleteProfessionalPractice = (props: IProfessionalPracticeProps) => {
    const { permissions } = getStorageItems();

    return !! ((
        permissions.includes('professionalPractices.delete')
        && canTouchRoadmap(props?.roadmap)
        && canTouchProfessionalPractice(props?.professionalPractice)
    ) || (
        props?.roadmap?.hasAccessThroughInternship
    ));
};

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- REFLEXIVE RESULT ------------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

interface IReflexiveResultProps {
    roadmap?: IRoadmap;
    reflexiveResult?: IReflexiveResult;
}

const canTouchReflexiveResult = (reflexiveResult: IReflexiveResult | undefined) => {
    const { storedUser } = getStorageItems();

    return !! (
        storedUser?.company?.id === reflexiveResult?.company?.id
        || storedUser?.companies?.some(c => c.id === reflexiveResult?.company?.id)
        || (storedUser?.role?.id && Number(storedUser.role.id) <= 2)
    );
};

export const canTouchOwnReflexiveResult = (learnerId: string | undefined) => {
    const { permissions, storedUser } = getStorageItems();

    return (
        permissions.includes('reflexiveResults.touchOwn')
        && storedUser?.id === learnerId
    );
};

export const canViewAnyReflexiveResult = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('reflexiveResults.viewAny');
};

export const canViewReflexiveResult = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('reflexiveResults.view');
};

export const canCreateReflexiveResult = (props?: IReflexiveResultProps) => {
    const { permissions } = getStorageItems();

    if (props?.roadmap) {
        return (
            props.roadmap?.status === ERoadmapStatus.VALIDATED
            && ((
                permissions.includes('reflexiveResults.create')
                && canTouchRoadmap(props.roadmap)
            ) || (
                props.roadmap?.hasAccessThroughInternship
            ))
        );
    }

    return permissions.includes('reflexiveResults.create');
};

export const canUpdateReflexiveResult = (props?: IReflexiveResultProps) => {
    const { permissions } = getStorageItems();

    if (props?.roadmap) {
        return (
            props.roadmap?.status !== ERoadmapStatus.DRAFT
            && ((
                permissions.includes('reflexiveResults.update')
                && canTouchRoadmap(props.roadmap)
                && canTouchReflexiveResult(props.reflexiveResult)
            ) || (
                props.roadmap?.hasAccessThroughInternship
            ))
        );
    }

    return permissions.includes('reflexiveResults.update');
};

export const canDeleteReflexiveResult = (props: IReflexiveResultProps) => {
    const { permissions } = getStorageItems();

    return !! ((
        permissions.includes('reflexiveResults.delete')
        && canTouchRoadmap(props?.roadmap)
        && canTouchReflexiveResult(props?.reflexiveResult)
    ) || (
        props?.roadmap?.hasAccessThroughInternship
    ));
};

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------------- OTHER TRAINING ------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

interface IOtherTrainingProps {
    otherTraining?: IOtherTraining;
    roadmap?: IRoadmap;
}

const canTouchOtherTraining = (otherTraining: IOtherTraining | undefined) => {
    const { storedUser } = getStorageItems();

    return !! (
        storedUser?.company?.id === otherTraining?.company?.id
        || storedUser?.companies?.some(c => c.id === otherTraining?.company?.id)
        || (storedUser?.role?.id && Number(storedUser.role.id) <= 2)
    );
};

export const canCreateOtherTraining = (roadmap?: IRoadmap) => {
    const { permissions } = getStorageItems();

    if (roadmap) {
        return (
            roadmap?.status === ERoadmapStatus.VALIDATED
            && ((
                permissions.includes('otherTrainings.create')
                && canTouchRoadmap(roadmap)
            ) || (
                roadmap?.hasAccessThroughInternship
            ))
        );
    }

    return permissions.includes('otherTrainings.create');
};

export const canUpdateOtherTraining = (props?: IOtherTrainingProps) => {
    const { permissions } = getStorageItems();

    if (props?.roadmap) {
        return (
            canTouchOtherTraining(props?.otherTraining)
            && props.roadmap?.status !== ERoadmapStatus.DRAFT
            && ((
                permissions.includes('otherTrainings.update')
                && canTouchRoadmap(props.roadmap)
            ) || (
                props.roadmap?.hasAccessThroughInternship
            ))
        );
    }

    return permissions.includes('otherTrainings.update');
};

export const canDeleteOtherTraining = (props?: IOtherTrainingProps) => {
    const { permissions } = getStorageItems();

    return !! (
        canTouchOtherTraining(props?.otherTraining)
        && ((
            permissions.includes('otherTrainings.delete')
            && canTouchRoadmap(props?.roadmap)
        ) || (
            props?.roadmap?.hasAccessThroughInternship
        ))
    );
}

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------- LEARNER WORK SITUATION --------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const canUpdateLearnerWorkSituation = (learnerWorkSituation: ILearnerWorkSituation | undefined) => {
    const { permissions, storedUser } = getStorageItems();

    return !! (
        learnerWorkSituation?.weather == null
        && permissions.includes('learnerWorkSituations.update')
        && (
            learnerWorkSituation?.learner?.id === storedUser?.id
            || (storedUser?.role?.id && Number(storedUser.role.id) <= 2)
        )
    );
};

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------- TRAINER WORK SITUATION --------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const canUpdateTrainerWorkSituation = (trainerWorkSituation: ITrainerWorkSituation | undefined) => {
    const { permissions, storedUser } = getStorageItems();

    return !! (
        trainerWorkSituation?.weather == null
        && permissions.includes('trainerWorkSituations.update')
        && (
            trainerWorkSituation?.trainer?.id === storedUser?.id
            || (storedUser?.role?.id && Number(storedUser.role.id) === 1)
        )
    );
};

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------ REFLECTION PHASE TRAINER -------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const canUpdateReflectionPhaseTrainer = (reflectionPhaseTrainer: IReflectionPhaseTrainer | undefined) => {
    const { permissions, storedUser } = getStorageItems();

    return !! (
        reflectionPhaseTrainer?.weather == null
        && permissions.includes('reflectionPhaseTrainers.update')
        && (
            reflectionPhaseTrainer?.trainer?.id === storedUser?.id
            || (storedUser?.role?.id && Number(storedUser.role.id) === 1)
        )
    );
};

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------- INTERNSHIP --------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

interface IInternshipProps {
    internship?: IInternship;
    roadmap?: IRoadmap;
}

const canTouchSimplifiedInternship = (internship: IInternship | undefined) => {
    const { storedUser } = getStorageItems();

    return !! (
        storedUser?.company?.id === internship?.company?.id
        || storedUser?.companies?.some(c => c.id === internship?.company?.id)
        || (storedUser?.role?.id && Number(storedUser.role.id) <= 2)
    );
};

export const canCreateSimplifiedInternship = (roadmap?: IRoadmap) => {
    const { permissions } = getStorageItems();

    if (roadmap) {
        return (
            permissions.includes('internships.createSimplified')
            && canTouchRoadmap(roadmap)
            && roadmap?.status !== ERoadmapStatus.DRAFT
        );
    }

    return permissions.includes('internships.createSimplified');
};

export const canUpdateSimplifiedInternship = (props?: IInternshipProps) => {
    const { permissions } = getStorageItems();

    if (props?.roadmap) {
        return (
            permissions.includes('internships.updateSimplified')
            && props.roadmap?.status !== ERoadmapStatus.DRAFT
            && canTouchRoadmap(props.roadmap)
            && canTouchSimplifiedInternship(props?.internship)
        );
    }

    return permissions.includes('internships.updateSimplified');
};

export const canDeleteSimplifiedInternship = (props?: IInternshipProps) => {
    const { permissions } = getStorageItems();

    return (
        permissions.includes('internships.deleteSimplified')
        && canTouchRoadmap(props?.roadmap)
        && canTouchSimplifiedInternship(props?.internship)
    );
};

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ STEP MODEL ---------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const canViewAnyStepModel = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('stepModels.viewAny');
};

export const canViewStepModel = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('stepModels.view');
};

export const canCreateStepModel = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('stepModels.create');
};

export const canUpdateStepModel = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('stepModels.update');
};

export const canDeleteStepModel = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('stepModels.delete');
};

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ ROADMAP MODEL ------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const canViewAnyRoadmapModel = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('roadmapModels.viewAny');
};

export const canViewRoadmapModel = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('roadmapModels.view');
};

export const canCreateRoadmapModel = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('roadmapModels.create');
};

export const canUpdateRoadmapModel = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('roadmapModels.update');
};

export const canDeleteRoadmapModel = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('roadmapModels.delete');
};

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- SIGNATURE GROUP ------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const canViewAnySignatureGroup = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('signatureGroups.viewAny');
};

export const canViewSignatureGroup = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('signatureGroups.view');
};

export const canCreateSignatureGroup = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('signatureGroups.create');
};

export const canUpdateSignatureGroup = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('signatureGroups.update');
};

export const canDeleteSignatureGroup = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('signatureGroups.delete');
};

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------------- CERTIFIER --------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const canViewAnyCertifier = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('certifiers.viewAny');
};

export const canViewCertifier = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('certifiers.view');
};

export const canCreateCertifier = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('certifiers.create');
};

export const canUpdateCertifier = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('certifiers.update');
};

export const canDeleteCertifier = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('certifiers.delete');
};

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ CERTIFICATION ------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const canViewAnyCertification = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('certifications.viewAny');
};

export const canViewCertification = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('certifications.view');
};

export const canCreateCertification = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('certifications.create');
};

export const canUpdateCertification = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('certifications.update');
};

export const canDeleteCertification = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('certifications.delete');
};

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- REFLEXIVITY AXIS ------------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

export const canViewAnyReflexivityAxis = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('reflexivityAxes.viewAny');
};

export const canViewReflexivityAxis = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('reflexivityAxes.view');
};

export const canCreateReflexivityAxis = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('reflexivityAxes.create');
};

export const canUpdateReflexivityAxis = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('reflexivityAxes.update');
};

export const canDeleteReflexivityAxis = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('reflexivityAxes.delete');
};

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------- REFLEXIVITY QUESTION ---------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const canViewAnyReflexivityQuestion = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('reflexivityQuestions.viewAny');
};

export const canViewReflexivityQuestion = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('reflexivityQuestions.view');
};

export const canCreateReflexivityQuestion = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('reflexivityQuestions.create');
};

export const canUpdateReflexivityQuestion = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('reflexivityQuestions.update');
};

export const canDeleteReflexivityQuestion = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('reflexivityQuestions.delete');
};

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------------- ARTICLE ----------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const canViewAnyArticle = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('articles.viewAny');
};

export const canViewArticle = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('articles.view');
};

export const canCreateArticle = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('articles.create');
};

export const canUpdateArticle = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('articles.update');
};

export const canDeleteArticle = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('articles.delete');
};

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- PEDAGOGICAL TOOL ------------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

export const canViewAnyPedagogicalTool = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('pedagogicalTools.viewAny');
};

export const canViewPedagogicalTool = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('pedagogicalTools.view');
};

export const canCreatePedagogicalTool = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('pedagogicalTools.create');
};

export const canUpdatePedagogicalTool = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('pedagogicalTools.update');
};

export const canDeletePedagogicalTool = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('pedagogicalTools.delete');
};

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------- PROOF ITEM --------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const canViewAnyProofItem = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('proofItems.viewAny');
};

export const canViewProofItem = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('proofItems.view');
};

export const canCreateProofItem = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('proofItems.create');
};

export const canUpdateProofItem = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('proofItems.update');
};

export const canDeleteProofItem = () => {
    const { permissions } = getStorageItems();
    return permissions.includes('proofItems.delete');
};
