import { PropTypes, SvgIconProps } from '@mui/material';
import { EStepType } from './enums';

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------------- ORIGIN LABEL --------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const originLabel = 'Zero Barrier';

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ COLOR TYPE ---------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export type ColorType = PropTypes.Color | 'success' | 'error' | 'info' | 'warning' | 'navyBlue' | 'yellow' | 'magenta';

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- DISCOVIA COLORS ------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const darkGrayMain = '#3c3c3c';

export const yellowLight = '#fdce00';
export const yellowMain = '#f5b200';
export const yellowDark  = '#e3a500';

export const magentaLight = '#d80080';
export const magentaMain = '#ac0066';
export const magentaDark = '#890f61';

export const navyBlueLight = '#4f66e8';
export const navyBlueMain = '#3f51b5';
export const navyBlueDark = '#2b377a';

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ MUI COLORS ---------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const primaryLight = '#42a5f5';
export const primaryMain = '#1976d2';
export const primaryDark = '#1565c0';

export const secondaryLight = '#ba68c8';
export const secondaryMain = '#9c27b0';
export const secondaryDark = '#7b1fa2';

export const successLight = '#4caf50';
export const successMain = '#2e7d32';
export const successDark = '#1b5e20';

export const warningMain = '#ed6c02';
export const errorMain = '#f44336';

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------- FORBIDDEN KEYS NUMBER INPUT ------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

export const forbiddenKeysNumberInput = ['-', '+', 'e'];

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------- FORBIDDEN KEYS EXCEL --------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const forbiddenKeysExcel = ['*', '?', ':', '/', '\'', '[', ']'];

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ EMAIL PATTERN ------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

// eslint-disable-next-line
export const emailPattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,6}$/g;

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------ STEP TYPES BELONGS TO AFEST ----------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const stepTypesBelongsToAFEST = [
    EStepType.PREPARATORY_SEQUENCE,
    EStepType.WORK_SITUATION,
    EStepType.REFLECTION_PHASE,
    EStepType.EVALUATION,
];

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------- STEP TYPES WITH CONTEXT ------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const stepTypesWithContext = [
    EStepType.PLACEMENT,
    EStepType.PREPARATORY_SEQUENCE,
    EStepType.WORK_SITUATION,
    EStepType.EVALUATION,
    EStepType.SUPPORT,
    EStepType.OTHER_TRAINING,
    EStepType.INTERNSHIP,
    EStepType.REFLEXIVE_RESULT,
];

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------- ALPHABET CHARS ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const alphabetChars = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------- ACCEPTED EXTENSIONS ---------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const acceptedExtensions = {
    document: [
        'pdf',
        'doc',
        'docx',
        'ppt',
        'pptx',
        'odt',
        'xls',
        'xlsx',
    ],
    image: [
        'jpg',
        'jpeg',
        'png',
        'gif',
    ],
    audio: [
        'mp3',
        'wav',
        'aac',
        'ogg',
        'wma',
        'flac',
        'aif',
        'aiff',
        'au',
        'mid',
        'midi',
        'dsf',
        'm4a',
    ],
    video: [
        'mp4',
        'asf',
        'avi',
        'm4v',
        'mov',
        'mpg',
        'mpeg',
        'wmv',
    ],
};

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ JSPDF AUTOTABLE ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const jspdfAutotableStyles = {
    font: 'Roboto',
    fontStyle: 'normal',
    fontSize: 10,
    halign: 'left',
    valign: 'middle',
    textColor: 'black',
    cellPadding: 3,
};

export const jspdfAutotableMargin = {
    top: 44,
    right: 15,
    bottom: 34,
    left: 15,
};

export const jspdfAutotableFillColor = '#404040';

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------- REPOSITORY ICON PROPS --------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const listCardRepositoryIconProps : SvgIconProps = { style: { width: 120, height: 120 }};
export const menuCardRepositoryIconProps : SvgIconProps = { style: { width: 160, height: 160 }};
