import { gql } from '@apollo/client';

export const LEARNER_WORK_SITUATION = gql`
    query LearnerWorkSituation($id: ID!) {
        learnerWorkSituation(id: $id) {
            id
            roadmap {
                id
                name
                endDate
                company {
                    id
                    name
                }
                customRepository {
                    id
                    name
                }
            }
            workSituation {
                id
                trainingDate
                endMorning
                endAfternoon
                stepModel {
                    id
                    reflexivityAxis {
                        id
                        name
                    }
                    reflexivityQuestions {
                        id
                        position
                        description
                    }
                }
                trainerWorkSituations {
                    id
                    trainer {
                        id
                        fullName
                    }
                }
                descriptors {
                    id
                    name
                    descriptorType
                    skill {
                        name
                    }
                }
                tasks {
                    id
                    name
                    position
                    activity {
                        id
                        name
                        position
                    }
                }
                atypicalItems {
                    id
                    name
                    parentItem {
                        id
                        name
                    }
                    atypicalRepository {
                        id
                        name
                    }
                }
                linkItems {
                    id
                    skillName
                    atypicalItem {
                        id
                        name
                        parentItem {
                            id
                            name
                        }
                        atypicalRepository {
                            id
                            name
                        }
                    }
                }
            }
        }
    }
`

export const CREATE_REFLECTION_PHASE = gql`
    mutation CreateReflectionPhase(
        $trainingDate: Date!
        $startTime: String!
        $endTime: String!
        $result: String
        $learnerWorkSituation: ConnectLearnerWorkSituationBelongsTo!
        $reflectionPhaseTrainers: CreateReflectionPhaseTrainersHasMany
        $reflexivityAxis: ConnectReflexivityAxisBelongsTo
        $reflexivityQuestions: SyncReflexivityQuestionsWithPivotBelongsToMany
    ) {
        createReflectionPhase(input: {
            trainingDate: $trainingDate
            startTime: $startTime
            endTime: $endTime
            result: $result
            learnerWorkSituation: $learnerWorkSituation
            reflectionPhaseTrainers: $reflectionPhaseTrainers
            reflexivityAxis: $reflexivityAxis
            reflexivityQuestions: $reflexivityQuestions
        }) {
            id
        }
    }
`
