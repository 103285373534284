
// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------------- DESTINATION APP ------------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

export enum EDestinationApp {
    HAPPINCO = 'HAPPINCO',
    USER_GUIDE = 'USER_GUIDE',
}

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------- SORT ORDER --------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export enum ESortOrder {
    ASC = 'ASC',
    DESC = 'DESC',
}

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------------- GENDER ----------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export enum EGender {
    UNKNOWN = 'UNKNOWN',
    MALE = 'MALE',
    FEMALE = 'FEMALE',
}

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- NOTIFICATION TYPE ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export enum ENotificationType {
    ALL = 'ALL',
    EMAIL = 'EMAIL',
    SMS = 'SMS',
    NONE = 'NONE',
}

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------- CERTIFICATION TYPE ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export enum ECertificationType {
    TITLE = 'TITLE',
    CQP = 'CQP',
    DIPLOMA = 'DIPLOMA',
    EUROPEAN = 'EUROPEAN',
}

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------- REPOSITORY STATUS ------------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

export enum ERepositoryStatus {
    DRAFT = 'DRAFT',
    IN_PROGRESS = 'IN_PROGRESS',
    AVAILABLE = 'AVAILABLE',
}

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------------- DESCRIPTOR TYPE ------------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

export enum EDescriptorType {
    KNOW_HOW = 'KNOW_HOW',
    KNOWLEDGE = 'KNOWLEDGE',
    APTITUDE = 'APTITUDE',
}

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ DURATION UNIT ------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export enum EDurationUnit {
    MINUTES = 'MINUTES',
    HOURS = 'HOURS',
    DAYS = 'DAYS',
    MONTHS = 'MONTHS',
}

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------------- MODALITY --------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export enum EModality {
    AFEST = 'AFEST',
    E_LEARNING = 'E_LEARNING',
    FACE_TO_FACE = 'FACE_TO_FACE',
    WORK_STUDY = 'WORK_STUDY',
    MULTIMODAL = 'MULTIMODAL',
}

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ ROADMAP STATUS ------------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

export enum ERoadmapStatus {
    DRAFT = 'DRAFT',
    VALIDATED = 'VALIDATED',
    ENDED = 'ENDED',
}

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- ROADMAP VIEW MODE ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export enum ERoadmapViewMode {
    TREE = 'TREE',
    LIST = 'LIST',
}

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------------- STEP TYPE -------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export enum EStepType {
    SELF_PLACEMENT = 'SELF_PLACEMENT',
    SELF_EVALUATION = 'SELF_EVALUATION',
    PLACEMENT = 'PLACEMENT',
    PREPARATORY_SEQUENCE = 'PREPARATORY_SEQUENCE',
    WORK_SITUATION = 'WORK_SITUATION',
    REFLECTION_PHASE = 'REFLECTION_PHASE',
    EVALUATION = 'EVALUATION',
    FINAL_EVALUATION = 'FINAL_EVALUATION',
    SUPPORT = 'SUPPORT',
    OTHER_TRAINING = 'OTHER_TRAINING',
    PROFESSIONAL_PRACTICE = 'PROFESSIONAL_PRACTICE',
    REFLEXIVE_RESULT = 'REFLEXIVE_RESULT',
    INTERNSHIP = 'INTERNSHIP',
}

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ EVALUATION TYPE ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export enum EEvaluationType {
    PLACEMENT = 'PLACEMENT',
    EVALUATION = 'EVALUATION',
    FINAL_EVALUATION = 'FINAL_EVALUATION',
}

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------------- WORK SITUATION TYPE -------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export enum EWorkSituationType {
    PREPARATORY_SEQUENCE = 'PREPARATORY_SEQUENCE',
    WORK_SITUATION = 'WORK_SITUATION',
}

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------------- MASTERY --------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export enum EMastery {
    VALIDATED = 'VALIDATED',
    NOT_VALIDATED = 'NOT_VALIDATED',
    NOT_OBSERVABLE = 'NOT_OBSERVABLE',
}

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------------- PLACE TYPE ------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export enum EPlaceType {
    INTERNAL = 'INTERNAL',
    EXTERNAL = 'EXTERNAL',
}

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------------------- WEATHER -------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export enum EWeather {
    SUNNY = 'SUNNY',
    CLOUDY = 'CLOUDY',
    RAINY = 'RAINY',
    STORMY = 'STORMY',
}

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------------- SCHEDULE -------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export enum ESchedule {
    MORNING = 'MORNING',
    AFTERNOON = 'AFTERNOON',
    DAY = 'DAY',
}

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------------- SUPPORT TYPE ------------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

export enum ESupportType {
    WORKSHOP = 'WORKSHOP',
    SOCIO_PROFESSIONAL_FOLLOW_UP = 'SOCIO_PROFESSIONAL_FOLLOW_UP',
    INDIVIDUAL_INTERVIEW = 'INDIVIDUAL_INTERVIEW',
    COLLECTIVE_MEETING = 'COLLECTIVE_MEETING',
    PREPARATION_FOR_PERIOD_IN_COMPANY = 'PREPARATION_FOR_PERIOD_IN_COMPANY',
}

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------- SUPPORT STATUS ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export enum ESupportStatus {
    WAITING = 'WAITING',
    VALIDATED = 'VALIDATED',
}

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------- REPOSITORY TYPE ---------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export enum ERepositoryType {
    SKILL = 'SKILL',
    CUSTOM = 'CUSTOM',
    ACTIVITY = 'ACTIVITY',
    ATYPICAL = 'ATYPICAL',
}

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- REPOSITORY ITEM TYPE -------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export enum ERepositoryItemType {
    CUSTOM_SKILL = 'CUSTOM_SKILL',
    DESCRIPTOR = 'DESCRIPTOR',
    ACTIVITY = 'ACTIVITY',
    TASK = 'TASK',
    ATYPICAL_ITEM = 'ATYPICAL_ITEM',
    LINK_ITEM = 'LINK_ITEM',
}

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------------- RESULT TYPE ------------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

export enum EResultType {
    CERTIFIED = 'CERTIFIED',
    PARTIALLY = 'PARTIALLY',
    VALIDATED = 'VALIDATED',
    TRAINING = 'TRAINING',
    PLANNED = 'PLANNED',
    CONTAINS = 'CONTAINS',
    NONE = 'NONE',
}

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------------- SELF MASTERY ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export enum ESelfMastery {
    KNOW = 'KNOW',
    KNOW_PARTIALLY = 'KNOW_PARTIALLY',
    DO_NOT_KNOW = 'DO_NOT_KNOW',
    DO_NOT_UNDERSTAND = 'DO_NOT_UNDERSTAND',
}

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- POST INTERNSHIP RESULT ------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

export enum EPostInternshipResult {
    NONE = 'NONE',
    POSITIVE = 'POSITIVE',
    TRANSITION = 'TRANSITION',
    SUSTAINABLE = 'SUSTAINABLE',
    SUSTAINABLE_NO_END = 'SUSTAINABLE_NO_END',
    PROFESSIONAL_PROJECT_VALIDATED = 'PROFESSIONAL_PROJECT_VALIDATED',
}

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------------- LINKABLE TYPE ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export enum ELinkableType {
    CUSTOM_REPOSITORY = 'CUSTOM_REPOSITORY',
    CUSTOM_BLOCK = 'CUSTOM_BLOCK',
    CUSTOM_SKILL = 'CUSTOM_SKILL',
    DESCRIPTOR = 'DESCRIPTOR',
}

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------------- LINKABLE TYPE ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export enum EOtherTrainingModality {
    E_LEARNING = 'E_LEARNING',
    FACE_TO_FACE = 'FACE_TO_FACE',
    WORK_STUDY = 'WORK_STUDY',
    WORKSHOP = 'WORKSHOP',
    TECHNICAL_PLATFORM = 'TECHNICAL_PLATFORM',
}

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------ PREPARATORY SEQUENCE MODALITY --------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export enum EPreparatorySequenceModality {
    SIMULATION = 'SIMULATION',
    TECHNICAL_PLATFORM = 'TECHNICAL_PLATFORM',
    PRESENTATION = 'PRESENTATION',
    DEMONSTRATION = 'DEMONSTRATION',
    THEORETICAL_CONTRIBUTIONS = 'THEORETICAL_CONTRIBUTIONS',
}

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------- ACTION REQUIRED ---------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export enum EActionRequired {
    WEATHER = 'WEATHER',
    SIGNATURE = 'SIGNATURE',
    REFLECTION_PHASE = 'REFLECTION_PHASE',
    VALIDATION = 'VALIDATION',
    PROOF_ITEMS = 'PROOF_ITEMS',
}

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------------- IMPORT TYPE ------------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

export enum EImportType {
    CREATION = 'CREATION',
    TRANSLATION = 'TRANSLATION',
}

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------------------- HONOUR --------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export enum EHonour {
    STANDARD_PASS = 'STANDARD_PASS',
    HONOURS = 'HONOURS',
    HIGH_HONOURS = 'HIGH_HONOURS',
    HIGHEST_HONOUR = 'HIGHEST_HONOUR',
}

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------------- VISIBILITY ------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export enum EVisibility {
    PUBLIC = 'PUBLIC',
    LIMITED = 'LIMITED',
    PRIVATE = 'PRIVATE',
}

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------------- VALIDITY --------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export enum EValidity {
    VALID = 'VALID',
    EXPIRED = 'EXPIRED',
}

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------- PROFESSIONAL PRACTICE STATUS ----------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export enum EProfessionalPracticeStatus {
    WAITING = 'WAITING',
    VALIDATED = 'VALIDATED',
}

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------------- INTERNSHIP STATUS ---------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export enum EInternshipStatus {
    DRAFT = 'DRAFT',
    WAITING = 'WAITING',
    VALIDATED = 'VALIDATED',
    STOPPED = 'STOPPED',
    CANCELLED = 'CANCELLED',
    REFUSED = 'REFUSED',
    ENDED = 'ENDED',
}

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- APPLICATION ACCESS ---------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export enum EApplicationAccess {
    HAS_ACCESS = 'HAS_ACCESS',
    DOESNT_HAVE_ACCESS = 'DOESNT_HAVE_ACCESS',
    ACCESS_EXPIRES_SOON = 'ACCESS_EXPIRES_SOON',
    ACCESS_EXPIRED = 'ACCESS_EXPIRED',
}

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ ARTICLE STATUS ------------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

export enum EArticleStatus {
    DRAFT = 'DRAFT',
    PUBLISHED = 'PUBLISHED',
    ARCHIVED = 'ARCHIVED',
}

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------- ARTICLE TYPE ------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export enum EArticleType {
    NEWS = 'NEWS',
    EVENT = 'EVENT',
    FUNCTIONALITY = 'FUNCTIONALITY',
}

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------------- CARD TYPE -------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export enum ECardType {
    ARTICLES = 'ARTICLES',
    STATISTICS = 'STATISTICS',
    PORTFOLIO = 'PORTFOLIO',
    NEXT_STEPS = 'NEXT_STEPS',
    SIGNATURE_GROUPS = 'SIGNATURE_GROUPS',
    ROADMAPS = 'ROADMAPS',
    LINKED_COMPANIES = 'LINKED_COMPANIES',
    USERS = 'USERS',
    PREPARATORY_SEQUENCES = 'PREPARATORY_SEQUENCES',
    WORK_SITUATIONS = 'WORK_SITUATIONS',
    PROOF_ITEMS = 'PROOF_ITEMS',
}

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------------- SECTOR TYPE ------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export enum ESectorType {
    REPOSITORY_CATEGORY = 'REPOSITORY_CATEGORY',
    TOOL_CATEGORY = 'TOOL_CATEGORY',
}

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------------- TOOL TYPE -------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export enum EToolType {
    DOCUMENT = 'DOCUMENT',
    GAME = 'GAME',
    VIDEO = 'VIDEO',
    PODCAST = 'PODCAST',
}

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- TOOL ACCESSIBILITY ---------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export enum EToolAccessibility {
    ALL = 'ALL',
    COMMON = 'COMMON',
    PRIVATE = 'PRIVATE',
    FAVORITE = 'FAVORITE',
}
