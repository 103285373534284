import { gql } from '@apollo/client';

export const SELF_PLACEMENT = gql`
    query SelfPlacement($id: ID!) {
        selfPlacement(id: $id) {
            id
            result
            placementDate
            company {
                id
                name
                postalCode
                city
                footer
            }
            learner {
                id
                fullName
            }
            stepModel {
                id
                name
                stepTypes
            }
            roadmap {
                id
                name
                learner {
                    id
                }
            }
            proofItems {
                id
                name
                fileUrl
                fileSize
                externalLink
                mastery
                comment
                createdAt
                updatedAt
                creator {
                    id
                    fullName
                }
                lastModifier {
                    id
                    fullName
                }
            }
            customRepository {
                id
                name
            }
            customSkills {
                id
                skill {
                    id
                    name
                    position
                }
                customBlock {
                    id
                    position
                }
                pivot {
                    selfMastery
                }
            }
            activities {
                id
                name
                position
                pivot {
                    selfMastery
                }
            }
            atypicalItems {
                id
                name
                parentItem {
                    id
                    name
                }
                atypicalRepository {
                    id
                    name
                }
                pivot {
                    selfMastery
                }
            }
        }
    }
`

export const SELF_PLACEMENT_RESULTS = gql`
    query SelfPlacementResults(
        $roadmapId: ID!
        $atypicalRepositoryId: ID
    ) {
        selfPlacementResults(
            roadmapId: $roadmapId
            atypicalRepositoryId: $atypicalRepositoryId
        ) {
            itemId
            itemType
            selfMastery
        }
    }
`
