import { SvgIconProps } from '@mui/material';
import {
    AccountTree as AccountTreeIcon, CheckBoxOutlined as CheckBoxOutlinedIcon, ViewAgenda as ViewAgendaIcon,
    Category as CategoryIcon, Business as BusinessIcon, AutoAwesomeMotion as AutoAwesomeMotionIcon, Insights as InsightsIcon,
    FormatListBulleted as FormatListBulletedButton, LocalActivity as LocalActivityIcon, GradeOutlined as GradeOutlinedIcon,
    Grade as GradeIcon, PsychologyAlt as PsychologyAltIcon, NewReleases as NewReleasesIcon, Star as StarIcon,
    Collections as CollectionsIcon, VerifiedUser as VerifiedUserIcon,
} from '@mui/icons-material';

// Services
import { yellowMain, magentaMain } from 'services/constants';

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------- COMPANY ICON ------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export function CompanyIcon(props: SvgIconProps) {
    return <BusinessIcon {...props} />;
}

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------------- MODEL ICON -------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export function ModelIcon(props: SvgIconProps) {
    return <AutoAwesomeMotionIcon {...props} />;
}

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------- ACTIVITY SECTOR ICON --------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export function ActivitySectorIcon(props: SvgIconProps) {
    return <CategoryIcon {...props} />;
}

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ CERTIFIER ICON ------------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

export function CertifierIcon(props: SvgIconProps) {
    return <LocalActivityIcon {...props} />;
}

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------- ROADMAP ICON ------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export function RoadmapIcon(props: SvgIconProps) {
    return <InsightsIcon {...props} />;
}

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------------- SKILL BLOCK ICON ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export function SkillBlockIcon(props: SvgIconProps) {
    return <AccountTreeIcon {...props} style={{ ...props.style, color: props.color || yellowMain, transform: 'rotate(270deg) scaleX(-1)' }} />;
}

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------- SKILL ICON --------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export function SkillIcon(props: SvgIconProps) {
    return <ViewAgendaIcon {...props} style={{ ...props.style, color: props.color || magentaMain }} />;
}

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- DESCRIPTOR ICON ------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export function DescriptorIcon(props: SvgIconProps) {
    return <FormatListBulletedButton {...props} color={props.color || 'primary'} />;
}

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ ACTIVITY ICON ------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export function ActivityIcon(props: SvgIconProps) {
    return <CategoryIcon {...props} />;
}

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------------- TASK ICON --------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export function TaskIcon(props: SvgIconProps) {
    return <ViewAgendaIcon {...props} />;
}

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------- ATYPICAL ITEM ICON ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export function AtypicalItemIcon(props: SvgIconProps) {
    return <FormatListBulletedButton {...props} color={props.color || 'primary'} />;
}

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------- SELECTED REPOSITORY ITEM ------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

export function SelectedRepositoryItemIcon(props: SvgIconProps) {
    return <CheckBoxOutlinedIcon {...props} />;
}

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------------- CERTIFIED ICON ------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export function CertifiedIcon(props: SvgIconProps) {
    return <GradeIcon {...props} />;
}

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------- REFLEXIVITY AXIS ICON --------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export function ReflexivityAxisIcon(props: SvgIconProps) {
    return <PsychologyAltIcon {...props} />;
}

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------- ARTICLE ICON ------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export function ArticleIcon(props: SvgIconProps) {
    return <NewReleasesIcon {...props} />;
}

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ FAVORITE ICON ------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export function FavoriteIcon(props: SvgIconProps) {
    return <StarIcon {...props} />;
}

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------- FAVORITE EMPTY ICON ---------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export function FavoriteEmptyIcon(props: SvgIconProps) {
    return <GradeOutlinedIcon {...props} />;
}

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------- PEDAGOGICAL TOOL ICON --------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export function PedagogicalToolIcon(props: SvgIconProps) {
    return <CollectionsIcon {...props} />;
}

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------------- PROOF ITEM ICON ------------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

export function ProofItemIcon(props: SvgIconProps) {
    return <VerifiedUserIcon {...props} />;
}
