import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { InputActionMeta, ActionMeta } from 'react-select';

// Lodash
import { debounce } from 'lodash';

// Apollo
import { useQuery } from '@apollo/client';
import { USERS } from 'views/authenticated/users/gql-users';

// Services
import { IUser } from 'services/interfaces';

// Components
import Select, { ISelectProps } from 'components/react-select';

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------------- SELECT USER ---------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

interface ISelectUserProps extends ISelectProps {
    onChange: (newValue: any) => void;
    placeholder?: string;
    companyId?: string;
    roleIds?: string[];
}

export default function SelectUser(props: ISelectUserProps) {

    const { t } = useTranslation();
    const [searchValue, setSearchValue] = useState('');

    const { loading, data } = useQuery<{ users: { data: IUser[] }}>(USERS, {
        variables: {
            page: 1,
            first: 10,
            search: '%' + searchValue + '%',
            companyId: props.companyId,
            roles: props.roleIds,
        },
    });

    const delayedOnSearch = useRef(debounce(value => setSearchValue(value), 500)).current;

    const handleDelayedOnSearch = (value: string, actionMeta: InputActionMeta) => {
        if (actionMeta.action === 'input-change') {
            delayedOnSearch(value);
        }
    };

    const handleOnChange = (value: any, actionMeta: ActionMeta<any>) => {
        if (actionMeta.action === 'clear') {
            setSearchValue('');
        }
        props.onChange(value);
    };

    return (
        <Select
            {...props}
            placeholder={props.placeholder || t('search_user')}
            onChange={(value, actionMeta) => handleOnChange(value, actionMeta)}
            onInputChange={(value, actionMeta) => handleDelayedOnSearch(value, actionMeta)}
            options={data?.users?.data ? data.users.data.map(u => ({ value: u.id, label: u.fullName, filter: `${u.firstName} ${u.lastName}` })) : []}
            isLoading={loading}
            hasFilterOption
        />
    );
}
