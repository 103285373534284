import { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

// Permissions
import {
    canViewAnyStepModel, canViewStepModel, canCreateStepModel, canUpdateStepModel,
    canViewAnyRoadmapModel, canViewRoadmapModel, canCreateRoadmapModel, canUpdateRoadmapModel,
} from 'permissions';
import { DocumentTitleRoute, RequirePerm } from '../index';

/* * *
* Lazy Imports
*/

const Models = lazy(() => import('../../../views/authenticated/models'));

// Step Models
const StepModels = lazy(() => import('../../../views/authenticated/models/step-models'));
const StepModel = lazy(() => import('../../../views/authenticated/models/step-models/step-model'));
const CreateStepModel = lazy(() => import('../../../views/authenticated/models/step-models/create-step-model'));
const UpdateStepModel = lazy(() => import('../../../views/authenticated/models/step-models/update-step-model'));

// Roadmap Models
const RoadmapModels = lazy(() => import('../../../views/authenticated/models/roadmap-models'));
const RoadmapModel = lazy(() => import('../../../views/authenticated/models/roadmap-models/roadmap-model'));
const CreateRoadmapModel = lazy(() => import('../../../views/authenticated/models/roadmap-models/create-roadmap-model'));
const UpdateRoadmapModel = lazy(() => import('../../../views/authenticated/models/roadmap-models/update-roadmap-model'));

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------------- MODEL ROUTES --------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function ModelRoutes() {
    return (
        <Routes>
            <Route index element={<Models />} />
            <Route path="step-models">
                <Route index element={<RequirePerm perm={canViewAnyStepModel()}><StepModels /></RequirePerm>} />
                <Route path="create" element={<RequirePerm perm={canCreateStepModel()}><CreateStepModel /></RequirePerm>} />
                <Route path=":id" element={<DocumentTitleRoute title="step_model" id="id" />}>
                    <Route index element={<RequirePerm perm={canViewStepModel()}><StepModel /></RequirePerm>} />
                    <Route path="update" element={<RequirePerm perm={canUpdateStepModel()}><UpdateStepModel /></RequirePerm>} />
                </Route>
            </Route>
            <Route path="roadmap-models">
                <Route index element={<RequirePerm perm={canViewAnyRoadmapModel()}><RoadmapModels /></RequirePerm>} />
                <Route path="create" element={<RequirePerm perm={canCreateRoadmapModel()}><CreateRoadmapModel /></RequirePerm>} />
                <Route path=":id" element={<DocumentTitleRoute title="roadmap_model" id="id" />}>
                    <Route index element={<RequirePerm perm={canViewRoadmapModel()}><RoadmapModel /></RequirePerm>} />
                    <Route path="update" element={<RequirePerm perm={canUpdateRoadmapModel()}><UpdateRoadmapModel /></RequirePerm>} />
                </Route>
            </Route>
        </Routes>
    );
}
