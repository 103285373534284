import { gql } from '@apollo/client';

export const WORK_SITUATION = gql`
    query WorkSituation($id: ID!) {
        workSituation(id: $id) {
            id
            workSituationType
            trainingDate
            startMorning
            endMorning
            startAfternoon
            endAfternoon
            comment
            placeType
            placeDetails
            signOffSheetUrl
            isSigned
            signatureDisabled
            stepModel {
                id
                name
                stepTypes
            }
            company {
                id
                name
            }
            customRepository {
                id
                name
            }
            trainerWorkSituations {
                id
                weather
                signatureUrl
                trainer {
                    id
                    fullName
                }
            }
            learnerWorkSituations {
                id
                weather
                signatureUrl
                result
                personalResult
                learner {
                    id
                    fullName
                }
                roadmap {
                    id
                    name
                }
                reflectionPhases {
                    id
                    trainingDate
                    startTime
                    endTime
                }
            }
            descriptors {
                id
                name
                descriptorType
                skill {
                    name
                }
            }
            tasks {
                id
                name
                position
                activity {
                    id
                    position
                }
            }
            atypicalItems {
                id
                name
                atypicalRepository {
                    id
                    name
                }
                parentItem {
                    id
                    name
                }
            }
            linkItems {
                id
                skillName
                atypicalItem {
                    id
                    name
                    parentItem {
                        id
                        name
                    }
                    atypicalRepository {
                        id
                        name
                    }
                }
            }
        }
    }
`
