import { ApolloClient, InMemoryCache, from, ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import i18next from 'i18next';

// Dayjs
import dayjs from 'dayjs';

// Storage
import { getToken, clearStorage } from './storage';

/* * *
* API URL
*/

export var API_URL : string;

switch (window.origin) {

    case 'http://localhost:3000':
    case 'http://localhost:3001':
        API_URL = 'http://127.0.0.1:8000/';
        break;

    default:
        API_URL = window.origin + '/';
        break;
}

/* * *
* HAPPINCO URL
*/

export var HAPPINCO_URL : string;

switch (window.origin) {

    case 'http://localhost:3000':
        HAPPINCO_URL = 'http://localhost:3001/';
        break;

    case 'http://localhost:3001':
        HAPPINCO_URL = 'http://localhost:3000/';
        break;
    
    case 'https://beta.v2.zerobarrier.eu':
        HAPPINCO_URL = 'https://beta.happinco.app/';
        break;

    case 'https://demo.zerobarrier.app/':
        HAPPINCO_URL = 'https://demo.happinco.app/';
        break;

    case 'https://zerobarrier.app':
        HAPPINCO_URL = 'https://happinco.app/';
        break;
}

/* * *
* USER GUIDE URL
*/

export var USER_GUIDE_URL : string;

switch (window.origin) {

    case 'http://localhost:3000':
        USER_GUIDE_URL = 'http://localhost:3001/';
        break;

    case 'http://localhost:3001':
        USER_GUIDE_URL = 'http://localhost:3000/';
        break;

    case 'https://zerobarrier.app':
    case 'https://demo.zerobarrier.app/':
    case 'https://beta.v2.zerobarrier.eu':
        USER_GUIDE_URL = 'https://guide.discovia.fr/';
        break;
}

/* * *
* APOLLO UPLOAD CLIENT
* Enable to send file on mutation
*/

const uploadLink = createUploadLink({
    uri: API_URL + 'graphql',
});

/* * *
* APOLLO AUTH LINK
* Handle request headers
*/

const authLink = setContext((_, { headers }) => {

    const token = getToken();

    return {
        headers: {
            ...headers,
            'authorization': token ? 'Bearer ' + token : '',
            'x-localization': i18next.language,
            'x-application': 'zerobarrier',
        },
    };
});

/* * *
* APOLLO AFTERWARE LINK
* Handle reload location when new version is available
*/

let currentTime = '';

const afterwareLink = new ApolloLink((operation, forward) => {

    return forward(operation).map(response => {

        let CURRENT_VERSION = localStorage.getItem('currentVersion');

        const context = operation.getContext();
        const { response: { headers } } = context;

        if (headers && CURRENT_VERSION) {

            const APP_VERSION = headers.get('Front-Version');

            if (
                APP_VERSION > CURRENT_VERSION
                && (
                    currentTime === ''
                    || dayjs().format('YYYY-MM-DD HH:mm') > dayjs(currentTime).add(1, 'm').format('YYYY-MM-DD HH:mm')
                )
            ) {

                currentTime = dayjs().format('YYYY-MM-DD HH:mm');
                localStorage.setItem('currentVersion', APP_VERSION);

                const event = new Event('updateVersion');
                document.dispatchEvent(event);
            }
        }

        return response;
    });
});

/* * *
* APOLLO ERROR LINK
* Handle logout when user is unauthenticated
*/

const errorLink = onError(({ graphQLErrors, networkError }) => {

    if (graphQLErrors) {

        for (let err of graphQLErrors) {

            console.error(`[GraphQL error]: ${err.message}`);
            
            if (err.message !== '' && err.message.includes('Unauthenticated')) {
                clearStorage();
                client.clearStore();
                window.location.reload();
            }
        }
    }
    
    if (networkError) {
        console.error(`[Network error]: ${networkError.message}`);
    }
});

// --------------------------------------------------------------------------------- \\
// ----------------------------------- CLIENT -------------------------------------- \\
// --------------------------------------------------------------------------------- \\

const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: from([authLink, afterwareLink, errorLink, uploadLink]),
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'network-only',
        },
        query: {
            fetchPolicy: 'network-only',
        },
    },
});

export default client;
