import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

// MUI
import { Grid, Divider, Fab, Dialog, DialogActions, DialogContent, Button } from '@mui/material';
import { Visibility as VisibilityIcon } from '@mui/icons-material';

// Services
import { ILearnerWorkSituation } from 'services/interfaces';
import { getBreadcrumbItems, getFormattedDate, getFormattedTime } from 'services/utils';

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------- REFLECTION PHASES DIALOG ------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

const ReflectionPhasesDialog = ({
    learnerWorkSituation,
    source,
    onClose,
} : {
    learnerWorkSituation: ILearnerWorkSituation,
    source?: 'USER' | 'COMPANY' | 'ROADMAP',
    onClose: VoidFunction,
}) => {

    const navigate = useNavigate();
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const { id } = useParams();

    function navigateTo(reflectionPhaseId?: string) {

        let basePath = pathname;

        if (source !== 'COMPANY' && id !== learnerWorkSituation.learner?.id) {

            const { workSituationLink } = getBreadcrumbItems({
                mainId: learnerWorkSituation.learner?.id,
                source: 'USER',
                items: [],
                workSituation: learnerWorkSituation.workSituation,
            });

            basePath = workSituationLink;
        }

        if (reflectionPhaseId) {
            navigate(basePath + '/reflection-phase/' + reflectionPhaseId);
        }
        else {
            navigate(basePath + '/create-reflection-phase/' + learnerWorkSituation.id);
        }
    }

    return (
        <Dialog
            open
            onClose={onClose}
            fullWidth
        >
            <DialogContent>
                <div className="dialog-title">
                    {t('reflection_phases')} {t('of.1')} {learnerWorkSituation.learner?.fullName}
                </div>
                {learnerWorkSituation.reflectionPhases.map(rp => (
                    <Grid
                        key={rp.id}
                        container
                        alignItems="center"
                        marginTop={2}
                    >
                        <Fab
                            onClick={() => navigateTo(rp.id)}
                            color="secondary"
                        >
                            <VisibilityIcon />
                        </Fab>
                        <Grid
                            item xs
                            marginLeft={1}
                        >
                            {`${t('reflection_phase')} ${t('of.2')} ${getFormattedDate(rp.trainingDate)} (${getFormattedTime(rp.startTime)} - ${getFormattedTime(rp.endTime)})`}
                        </Grid>
                    </Grid>
                ))}
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button
                    onClick={onClose}
                    color="inherit"
                >
                    {t('close')}
                </Button>
                <Button
                    onClick={() => navigateTo()}
                    color="secondary"
                >
                    {t('add')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ReflectionPhasesDialog;
