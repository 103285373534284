import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { Grid, Fab, Tooltip } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';

// Services
import { ISelectOptionWithUserAttributes } from 'services/interfaces';

// Components
import Title from 'components/title';

// Selects
import SelectUser from 'selects/select-user';

// Views
import AddUserDialog from './add-user-dialog';

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------- SELECT OR CREATE USER ---------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

const SelectOrCreateUser = ({
    value,
    onChange,
    roleIds = [],
    label,
    dialogTitle,
    color = 'primary',
    isMulti = false,
    required = false,
    disabledCreate = false,
    containerProps,
} : {
    value: any,
    onChange: (value: any) => void,
    roleIds?: string[],
    label?: string,
    dialogTitle?: string,
    color?: 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning' | 'magenta' | 'yellow',
    isMulti?: boolean,
    required?: boolean,
    disabledCreate?: boolean,
    containerProps?: React.CSSProperties,
}) => {

    const { t } = useTranslation();
    const [open, setOpen] = useState(false);

    const onDialogSubmit = (user: ISelectOptionWithUserAttributes) => {

        setOpen(false);

        if (isMulti) {
            onChange([...value, user]);
        }
        else {
            onChange(user);
        }
    };

    return (
        <>
            <div style={containerProps}>
                <Title required={required}>
                    {label || t('user')}
                </Title>
                <Grid
                    container
                    alignItems="center"
                >
                    <Grid item xs>
                        <SelectUser
                            value={value}
                            onChange={(value: any) => onChange(value)}
                            roleIds={roleIds}
                            isMulti={isMulti}
                        />
                    </Grid>
                    {!disabledCreate && (
                        <Grid
                            item xs="auto"
                            marginLeft={1}
                        >
                            <Tooltip title={dialogTitle || t('add_user')}> 
                                <Fab
                                    onClick={() => setOpen(true)}
                                    color={color}
                                >
                                    <AddIcon />
                                </Fab>
                            </Tooltip>
                        </Grid>
                    )}
                </Grid>
            </div>
            {open && (
                <AddUserDialog
                    title={dialogTitle || t('add_user')}
                    onClose={() => setOpen(false)}
                    onSubmit={value => onDialogSubmit(value)}
                    color={color}
                />
            )}
        </>
    );
};

export default SelectOrCreateUser;
