import { gql } from '@apollo/client';

export const REFLECTION_PHASE = gql`
    query ReflectionPhase($id: ID!) {
        reflectionPhase(id: $id) {
            id
            signOffSheetUrl
            learnerSignatureUrl
            trainingDate
            learnerWorkSituation {
                id
                learner {
                    id
                    fullName
                }
                roadmap {
                    id
                    name
                    customRepository {
                        id
                        name
                    }
                    company {
                        id
                        name
                    }
                }
                workSituation {
                    id
                    customRepository {
                      id
                      name
                    }
                    company {
                      id
                      name
                    }
                }
            }
            reflectionPhaseTrainers {
                id
                signatureUrl
                trainer {
                    id
                    fullName
                }
            }
        }
    }
`
